<template>
  <nav class="mobile-bottom-nav hidden-lg-and-up d-print-none">
    <div class="mobile-bottom-nav__item mobile-bottom-nav__item--active">
      <router-link
        :to="{name : 'Home'}"
        class="mobile-bottom-nav__item-content"
      >
        <v-icon>mdi-home</v-icon>
        Home
      </router-link>
    </div>
    <div class="mobile-bottom-nav__item">
      <div
        class="mobile-bottom-nav__item-content"
        @click="navigationDrawer"
      >
        <v-icon>mdi-apps</v-icon>
        Categories
      </div>
    </div>
    <div class="mobile-bottom-nav__item">
      <router-link
        :disabled="!cartContent"
        :to="{name : 'CartDetails'}"
        class="mobile-bottom-nav__item-content"
      >
        <span class="mobile-bottom-nav__item-content__cart">
          <v-icon>mdi-cart</v-icon>
          <sup v-if="cartContent > 0">
            {{ cartContent }}
          </sup>
        </span>

        Cart
      </router-link>
    </div>
    <div
      v-if="isAuthenticated"
      class="mobile-bottom-nav__item"
    >
      <router-link
        :to="{name: routeMap.user.profile.name}"
        class="mobile-bottom-nav__item-content"
      >
        <v-icon>mdi-account</v-icon>
        Account
      </router-link>
    </div>
    <div
      v-else
      class="mobile-bottom-nav__item"
    >
      <router-link
        :to="{name: 'Login'}"
        class="mobile-bottom-nav__item-content"
      >
        <v-icon>mdi-lock</v-icon>
        Login
      </router-link>
    </div>
  </nav>
</template>
<script>
import routeMap from '@/utils/routeMap';
import { ADMIN_BASE_URL, avatarText, storeHelper } from '@/utils/helper';
import { CART_COUNT, cartNameSpace } from '@/store/modules/cart/types';
import CartButton from '@/components/Utils/CartButton';

export default {
  name: 'MobileBottomNav',
  components: { CartButton },
  data: () => ({
    routeMap,
    value: '',
    adminRoute: ADMIN_BASE_URL,

    authenticatedRoutes: [
      {
        title: 'Profile',
        routeName: 'UserProfile',
        link: '',
        icon: ''
      },
    ],
    authenticationRoute: [
      {
        title: 'Login',
        routeName: 'Login',
        link: '',
        icon: ''
      },
      {
        title: 'Admin Login',
        routeName: 'Login',
        link: '',
        icon: ''
      },
    ],
  }),
  computed: {
    authenticatedUser(){
      return this.$store.getters['user/data'];
    },
    cartContent() {
      return this.$store.getters[storeHelper(cartNameSpace, CART_COUNT)];
    },
    isAuthenticated() {
      return this.$store.getters['app/isLoggedIn'];
    }
  },
  methods: {

    navigationDrawer() {
      this.$store.dispatch('app/setCategoryNavigationDrawer', !this.$store.getters['app/categoryNavigationDrawer']);
    },

    async logout() {
      await this.$store.dispatch('app/tryLogout');
      await this.$router.push({ path: '/' });
    },

    avatarText(name) {

      if(!name) {
        return '';
      }

      return avatarText(name[0]);
    }
  }
};
</script>

<style lang="scss">

.mobile-bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 7;
  will-change: transform;
  transform: translateZ(0);
  display: flex;
  height: 76px;
  box-shadow: 0 -2px 5px -2px #333;
  background-color: var(--primary-color);
}

.top-nav {
  .v-toolbar__content {
    border-bottom: 1px solid #74c9f3;
  }
}

.mobile-bottom-nav__item-content,
.mobile-bottom-nav__item-content i
{
  color: #fff !important;
}

.mobile-bottom-nav__item-content {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.mobile-bottom-nav__item-content__cart {
  position: relative;
  width: fit-content;
  margin: 0 auto;

  sup {
    color: var(--blue-900);
    font-size: 12px;
    height: 24px;
    width: 24px;
    //padding: 8px 5px;
    pointer-events: auto;
    position: absolute;
    text-align: center;
    text-indent: 0;
    top: -15px;
    right: -18px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    white-space: nowrap;
    background: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}

.mobile-bottom-nav__item {
  flex-grow: 1;
  text-align: center;
  font-size: 13.5px;
  letter-spacing: 0.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile-bottom-nav__item:has(> a.mobile-bottom-nav__item-content.router-link-exact-active.router-link-active)
{
  background-color: rgba(249, 250, 251, 0.25);
}


</style>
