<template>
  <v-card>
    <v-navigation-drawer
      v-model="showDrawer"
      fixed
      temporary
      width="100%"
      class="mobile-nav d-print-none"
    >
      <template #prepend>
        <v-card-title class="d-flex justify-space-between pa-2 ma-0">
          <router-link :to="{name : 'Home'}">
            <v-avatar
              height="65"
              tile
              width="auto"
            >
              <img
                :alt="$apps.name"
                :src="require('@/assets/images/UK-Bangla-Marketplace-logo.png')"
              >
            </v-avatar>
          </router-link>

          <v-btn
            icon
            color="secondary"
            outlined
            @click="showDrawer = !showDrawer"
          >
            <v-icon color="secondary">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
      </template>
      <v-divider />

      <v-tabs vertical>
        <v-tab class="category-name">
          Popular Products
        </v-tab>
        <v-tab
          v-for="(category, index) in products.categories"
          :key="`product-category-${index}`"
          class="category-name"
        >
          {{ category.name }}
        </v-tab>
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col
                  v-for="(item, idx) in products.popularProducts"
                  :key="idx"
                  cols="4"
                >
                  <router-link
                    :title="item.name"
                    :to="routeToProductDetails(item)"
                  >
                    <figure @click="setProduct(item)">
                      <v-img
                        v-if="item.thumbnail"
                        class="category-image"
                        contain
                        aspect-ratio="1.4"
                        :src="item.thumbnail"
                      />
                      <figcaption>{{ textClipper(item.name) }}</figcaption>
                      <figcaption>Price: {{ item.price | currency }}</figcaption>
                    </figure>
                  </router-link>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
          v-for="(category, index) in products.categories"
          :key="`product-category-${index}`"
        >
          <v-card flat>
            <v-card-text>
              <!--              v-for="(subCategory, i) in category.sub_categories"-->
              <!--              :key="`sub-category${i}`"-->
              <!--              {{  subCategory.name }}-->
              <v-row>
                <v-col
                  v-for="(subCategory, i) in category.sub_categories"
                  :key="`sub-category${i}`"
                  cols="4"
                >
                  <router-link :to="{name: routeMap.products.name, query: {category: category.id, sub : subCategory.id}}">
                    <figure>
                      <v-img
                        v-if="subCategory.thumbnail"
                        :src="subCategory.thumbnail"
                        class="category-image"
                        max-height="150"
                        max-width="100%"
                      />
                      <figcaption>
                        {{ textClipper(subCategory.name) }}
                      </figcaption>
                    </figure>
                  </router-link>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import routeMap from '@/utils/routeMap';
import MobileNavItem from '@/components/Utils/MobileNavItem';
import {API_BASE_URL, avatarText, textClipper} from '@/utils/helper';

export default {
  name: 'CategoryNavigationDrawer',
  components: { MobileNavItem },
  data: () => ({
    defaultImage: `${API_BASE_URL}/storage/no-images.jpg`,
    routeMap,
    textClipper,

    showDrawer: false,
  }),
  computed: {
    ...mapGetters({
      currentDrawerState: 'app/categoryNavigationDrawer',
      hasAuthenticatedUser: 'user/hasData'
    }),

    isAuthenticated() {
      return this.$store.getters['app/isLoggedIn'];
    },

    authenticatedUser(){
      return this.$store.getters['user/data'];
    },

    products(){
      return {
        title: 'Products',
        routeName: routeMap.home.name,
        link: routeMap.products.path,
        icon: 'mdi-view-grid',
        categories: this.$store.getters['home/categories'],
        popularProducts: this.$store.getters['home/popular_products'],
      };
    }
  },
  watch: {
    currentDrawerState: {
      handler(nv) {
        this.showDrawer = nv;
      },
      immediate: true,
    },
    showDrawer: {

      handler(val) {
        if(!val) {
          this.$store.dispatch('app/setCategoryNavigationDrawer', val);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.showDrawer = this.currentDrawerState;
  },
  methods: {
    ...mapActions({
      setProduct: 'product/setProduct',
    }),

    async logout() {
      await this.$store.dispatch('app/tryLogout');
      await this.$router.push({ path: '/' });
    },

    images(item) {
      if (!item.images?.length) {
        return this.defaultImage;
      }

      return item.images[0]?.url;
    },

    avatarText(name) {

      if(!name) {
        return '';
      }

      return avatarText(name[0]);
    },
    categoryImage(item) {
      if (!item.logo) {
        return this.defaultImage;
      }

      return item?.logo?.url;
    },

    routeToProductDetails(item) {
      return {
        name: 'ProductSingle',
        params: {
          variation: item.slug,
          product: item.product.slug,
          vendor: item.product.shop.slug
        }
      };
    }
  }
};
</script>

<style scoped>
.mobile-nav {
  max-width: 100%;
}
.category-name {
  color: #fff !important;
}
.v-tab {
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
  width: 100% !important;
  font-size: 10px;
  text-transform: capitalize !important;
}
.v-tab:last-child {
  border-bottom: none;
}
.v-tab.v-tab--active {
  background: #fff !important;
  color: #012169 !important;
  /*width: 107% !important;*/
}

</style>
<style>
.v-slide-group__wrapper {
  background: #ccc !important;
  height: 100vh;
}
.v-tab {
  padding: 0px 16px;
  white-space: pre-line;
}
.v-tabs--vertical>.v-tabs-bar .v-tabs-bar__content {
  max-width: 125px !important;
  padding: 7px 0 0 1px !important;
}
figcaption {
  font-size: 10px;
  line-height: 1.5;
  color: #012169;
  text-transform: uppercase;
}
.category-image {
  border-radius: 7px;
  padding: 2px;
  border: 1px solid #202e82;
  /* background: #ccc; */
  margin-bottom: 3px;
}
</style>

