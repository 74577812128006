<template>
  <v-list-item
    link
    exact
    :to="{name: navigationLink.routeName}"
    active-class="secondary"
    ripple
  >
    <v-list-item-icon v-if="navigationLink.icon">
      <v-icon>{{ navigationLink.icon }}</v-icon>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title> {{ navigationLink.title }} </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>

export default {

  name: 'MobileNavItem',

  props: {
    link: {
      type: Object,
      default: () => {},
    }
  },
  data: ()=> ({
    navigationLink: {},
  }),

  watch: {
    link: {

      handler(nv){
        this.navigationLink = nv;
      },

      immediate: true
    },
  }
};
</script>
<style scoped lang="scss">
@import "../../assets/stylesheets/bootstrap";

.secondary.v-list-item--active.v-list-item.v-list-item--link.theme--light{
  color: #FFFFFF;
}

.v-list-item--link{
  &::before{
    background-color: rgba($color-secondary, 1);
  }
}
</style>
